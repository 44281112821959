@tailwind base;
@tailwind components;
@tailwind utilities;

html,
body {
  width: 100%;
  /* overflow-x: clip; */
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.register {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.switch-container {
  width: 300px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.switch-container__switch-option {
  width: 30%;
}

.switch-container__switch-option--selected {
  font-weight: bold;
}

.switch-container__switch-option--deselected {
  font-weight: 300;
}

.switch {
  position: relative;
  display: flex;
  align-items: center;
  width: 35px;
  height: 17px;
  margin: 0 10px;
  background-color: #ccc;
  cursor: pointer;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  display: inline-block;
  width: 13px;
  height: 13px;
  background-color: #fff;
  transform: translateX(-5px);
  transition: transform 0.5s;
}

.switch input:checked ~ .slider {
  transform: translateX(13px);
}

.register__form {
  display: flex;
  flex-direction: column;
}

.changeable-number-outer-container {
  display: flex;
  justify-content: center;
}

.changeable-number-inner-container {
  position: relative;
}

.changeable-number-inner-container--changed::after {
  content: "";
  color: green;
  position: absolute;
  height: 15px;
  width: 15px;
  border-radius: 50%;
  background-color: green;
  right: -20px;
  top: 3px;
}
